.sun-and-moon > :is(.moon,.sun,.sun-beams) {
  transform-origin: center center;
}

.sun-and-moon > .moon > circle {
  transform: translate(2px)
}

.sun-and-moon > :is(.moon,.sun) {
  fill: var(--foreground-color);
  stroke: var(--foreground-color)
}

.theme-toggle:is(:hover,:focus-visible) > .sun-and-moon > :is(.moon,.sun) {
  fill: var(--foreground-color);
  stroke: var(--foreground-color)
}

.sun-and-moon > .sun-beams {
  stroke: var(--foreground-color);
  stroke-width: 2px
}

.theme-toggle:is(:hover,:focus-visible) .sun-and-moon > .sun-beams {
  stroke: var(--foreground-color)
}

:is([color-scheme="dark"], .dark) .sun-and-moon > .sun {
  transform: scale(1.75)
}

:is([color-scheme="dark"], .dark) .sun-and-moon > .sun-beams {
  opacity: 0
}

:is([color-scheme="dark"], .dark) .sun-and-moon > .moon > circle {
  transform: translate(-7px)
}

@supports (cx:1) {
  :is([color-scheme="dark"], .dark) .sun-and-moon > .moon > circle {
      transform: translate(0);
      cx: 17
  }
}

@media (prefers-reduced-motion:no-preference) {
  .sun-and-moon > .sun {
      transition: transform 0.5s cubic-bezier(.5,1.25,.75,1.25)
  }

  .sun-and-moon > .sun-beams {
      transition:transform 0.5s cubic-bezier(.5,1.5,.75,1.25),opacity 0.5s cubic-bezier(.25,0,.3,1);
  }

  .sun-and-moon .moon > circle {
      transition: transform 0.25s cubic-bezier(0,0,0,1)
  }

  @supports (cx:1) {
      .sun-and-moon .moon > circle {
          transition: cx 0.25s cubic-bezier(0,0,0,1)
      }
  }

  :is([color-scheme="dark"], .dark) .sun-and-moon > .sun {
      transform: scale(1.75);
      transition-timing-function: cubic-bezier(.25,0,.3,1);
      transition-duration: 0.25s
  }

  :is([color-scheme="dark"], .dark) .sun-and-moon > .sun-beams {
      transform:rotate(-25deg);
      transition-duration:0.15s;
  }

  :is([color-scheme="dark"], .dark) .sun-and-moon > .moon > circle {
      transition-delay: 0.25s;
      transition-duration: 0.5s
  }
}