/* thai */
@font-face {
  font-family: '__K2D_93e692';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/893b6213298bbdfe-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__K2D_93e692';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/17591b84e22fb302-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__K2D_93e692';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ad7708e0325cb9cb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__K2D_93e692';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1ce92827bcd16872-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__K2D_93e692';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c37c8de67b3f6ccf-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__K2D_93e692';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/65c636df65ffa2b6-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__K2D_93e692';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/4b227d933f72d6d8-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__K2D_93e692';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/6a21fa3ccc539220-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__K2D_93e692';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/244ce91ea5133e41-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__K2D_93e692';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/82170bfbc26bb2ee-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__K2D_93e692';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/9790d8aa42c912cc-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__K2D_93e692';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/d766b73a5847da50-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
  font-family: '__K2D_93e692';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/44db051f2076a9e3-s.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: '__K2D_93e692';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f5d1c83d69c26a72-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__K2D_93e692';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8a17babd77d96b84-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__K2D_93e692';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7f021de7eb66aa06-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__K2D_Fallback_93e692';src: local("Arial");ascent-override: 102.23%;descent-override: 24.58%;line-gap-override: 0.00%;size-adjust: 102.51%
}.__className_93e692 {font-family: '__K2D_93e692', '__K2D_Fallback_93e692';font-style: normal
}

.sun-and-moon > :is(.moon,.sun,.sun-beams) {
  transform-origin: center center;
}

.sun-and-moon > .moon > circle {
  transform: translate(2px)
}

.sun-and-moon > :is(.moon,.sun) {
  fill: var(--foreground-color);
  stroke: var(--foreground-color)
}

.theme-toggle:is(:hover,:focus-visible) > .sun-and-moon > :is(.moon,.sun) {
  fill: var(--foreground-color);
  stroke: var(--foreground-color)
}

.sun-and-moon > .sun-beams {
  stroke: var(--foreground-color);
  stroke-width: 2px
}

.theme-toggle:is(:hover,:focus-visible) .sun-and-moon > .sun-beams {
  stroke: var(--foreground-color)
}

:is([color-scheme="dark"], .dark) .sun-and-moon > .sun {
  transform: scale(1.75)
}

:is([color-scheme="dark"], .dark) .sun-and-moon > .sun-beams {
  opacity: 0
}

:is([color-scheme="dark"], .dark) .sun-and-moon > .moon > circle {
  transform: translate(-7px)
}

@supports (cx:1) {
  :is([color-scheme="dark"], .dark) .sun-and-moon > .moon > circle {
      transform: translate(0);
      cx: 17
  }
}

@media (prefers-reduced-motion:no-preference) {
  .sun-and-moon > .sun {
      transition: transform 0.5s cubic-bezier(.5,1.25,.75,1.25)
  }

  .sun-and-moon > .sun-beams {
      transition:transform 0.5s cubic-bezier(.5,1.5,.75,1.25),opacity 0.5s cubic-bezier(.25,0,.3,1);
  }

  .sun-and-moon .moon > circle {
      transition: transform 0.25s cubic-bezier(0,0,0,1)
  }

  @supports (cx:1) {
      .sun-and-moon .moon > circle {
          transition: cx 0.25s cubic-bezier(0,0,0,1)
      }
  }

  :is([color-scheme="dark"], .dark) .sun-and-moon > .sun {
      transform: scale(1.75);
      transition-timing-function: cubic-bezier(.25,0,.3,1);
      transition-duration: 0.25s
  }

  :is([color-scheme="dark"], .dark) .sun-and-moon > .sun-beams {
      transform:rotate(-25deg);
      transition-duration:0.15s;
  }

  :is([color-scheme="dark"], .dark) .sun-and-moon > .moon > circle {
      transition-delay: 0.25s;
      transition-duration: 0.5s
  }
}
